<template>
  <section>
    <TitleBar tabsParent="referentiel">
      R&eacute;f&eacute;rentiel
      <template #toolbar-content>
        <TableButtonGroup
          :tableKey="tableKey"
          @rechercher="rechercher"
        ></TableButtonGroup>
      </template>
    </TitleBar>
    <b-container fluid>
      <ErrorComponent :error="error" @hide="error = null"></ErrorComponent>
      <PaginatedTable
        :fields="fields"
        :busy="busy"
        :pageable="pageable"
        :page="page"
        @change="pageableChanged"
      >
        <template #cell(adresse)="{ item }">
          <AddressComponent :value="item.adresse"></AddressComponent>
        </template>
        <template #cell(telephonePortable)="{ item }">
          <PhoneNumberComponent :value="item.telephonePortable">
          </PhoneNumberComponent>
        </template>
        <template #cell(telephoneFixe)="{ item }">
          <PhoneNumberComponent :value="item.telephoneFixe">
          </PhoneNumberComponent>
        </template>
        <template #cell(telephoneFax)="{ item }">
          <PhoneNumberComponent :value="item.telephoneFax">
          </PhoneNumberComponent>
        </template>
        <template #cell(actif)="{ item }">
          <b-icon-check-circle-fill v-if="item.actif" variant="success">
          </b-icon-check-circle-fill>
          <b-icon-x-circle-fill
            v-if="!item.actif"
            variant="danger"
          ></b-icon-x-circle-fill>
        </template>
      </PaginatedTable>
    </b-container>
  </section>
</template>

<script>
import TitleBar from "../../components/TitleBar.vue";
import TableButtonGroup from "../../components/controls/TableButtonGroup.vue";
import ErrorComponent from "../../components/ErrorComponent.vue";
import PaginatedTable from "../../components/PaginatedTable.vue";
import AddressComponent from "../../components/AddressComponent.vue";
import PhoneNumberComponent from "../../components/PhoneNumberComponent.vue";
import ReferentielService from "../../services/referentiel.service";
import { TableKeys } from "../../store/tables.defaults";
import {
  dataFactory as tableDataFactory,
  created,
  computed as tableComputed,
  methods as tableMethods,
} from "../../services/tables.service";
import UtilsService from "../../services/utils.service";
export default {
  name: "ReferentielTiersView",
  components: {
    TitleBar,
    TableButtonGroup,
    ErrorComponent,
    PaginatedTable,
    AddressComponent,
    PhoneNumberComponent,
  },
  data() {
    return {
      ...tableDataFactory(TableKeys.TIERS),
      error: null,
      page: null,
    };
  },
  computed: {
    ...tableComputed,
  },
  created,
  mounted() {
    this.load();
  },
  methods: {
    ...tableMethods,
    async load() {
      this.error = null;
      try {
        this.busy = true;
        let payload = this.getSearchPayload();
        let response = await ReferentielService.getTiers(payload);
        response.data.content.forEach((t) => {
          t.adresse = t.getAdresseComplete();
        });
        this.page = response.data;
      } catch (error) {
        this.page = null;
        this.error = UtilsService.handleError(error);
      } finally {
        this.busy = false;
      }
    },
  },
};
</script>